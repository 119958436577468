import { UserMenu } from '@components/UserMenu';

import styles from './styles.module.scss';

export const Header = ({ title }) => {
    return (
        <div className={styles.line1}>
            <div className={styles.title}>{title}</div>
            <UserMenu />
        </div>
    );
};
