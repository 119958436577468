export const nameItemsList = {
    date: 'Дата',
    time: 'Время',
    index: 'Индекс',
    route: 'Прикрепить к маршруту',
    volume: 'Объем',
    mass: 'Масса',
    places: 'Кол-во мест',
    workers_required: 'Грузчиков',
    max_size: 'М. габарит',
    floor: 'Этаж',
    carrying_distance: 'Пронос',
    has_elevator: 'Лифт',
    shipment_type: 'Характер груза',
    address: 'Адрес',
    driver: 'ФИО водителя',
    phones: 'Телефоны водителя',
};

export const formatError = (messages, defaultMessage) => {
    if (!messages) {
        return defaultMessage;
    }

    let errorMessage = ``;

    for (const [name, message] of Object.entries(messages)) {
        const itemMessage = message?.join(';');

        errorMessage = `${errorMessage}
        Поле ${nameItemsList[name]}: ${itemMessage}
        `;
    }

    return errorMessage;
};
