import axios from 'axios';

import { BACKEND_URL, getToken } from '@utils';

const defaultAxiosConfig = {
    responseType: 'json',
    method: 'GET',
};

const requestCounters = [];

function fetchRequest(reducer) {
    return {
        type: `${reducer}_REQUEST`,
    };
}
function fetchSuccess(data, reducer) {
    return {
        type: `${reducer}_SUCCESS`,
        payload: data,
    };
}
function fetchFailure(data, reducer) {
    return {
        type: `${reducer}_FAILURE`,
        error: data,
    };
}

export const fetch = (reducer, url, config = {}) => {
    return (params, data = {}) => {
        requestCounters[reducer] = (requestCounters[reducer] || 0) + 1;
        const _requestID = requestCounters[reducer];

        return (dispatch) => {
            dispatch(fetchRequest(reducer));
            return new Promise((resolve, reject) => {
                axios({
                    ...defaultAxiosConfig,
                    ...config,
                    url: `${BACKEND_URL}${url}`,
                    params,
                    data,
                    headers: { Authorization: `Bearer ${getToken()}` },
                })
                    .then((data) => {
                        if (requestCounters[reducer] === _requestID && getToken()) {
                            dispatch(fetchSuccess(data, reducer));
                        }
                        resolve({ payload: data });
                    })
                    .catch((error) => {
                        const { response } = error;
                        if (response) {
                            const error_code = response.status;
                            if (error_code === 403 || error_code === 401) {
                                dispatch({
                                    type: 'LOG_OUT',
                                });
                            }
                        }
                        dispatch(fetchFailure(error, reducer));
                        reject({ error });
                    });
            });
        };
    };
};
