import axios from 'axios';
import { parse } from 'content-disposition';
import FileDownload from 'js-file-download';

import { BACKEND_URL } from '@utils/constants';
import { getToken } from '@utils/token';

export const downloadFile =
    ({ url, filename, onError, params = {}, method = 'get' }) =>
    () => {
        const token = getToken();
        let currentFileName = filename;

        return axios({
            method,
            url: `${BACKEND_URL}${url}`,
            params,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        })
            .then((response) => {
                try {
                    const disposition = parse(
                        response.request.getResponseHeader('Content-Disposition')
                    );
                    currentFileName = disposition.parameters.filename;
                } catch (exception) {
                    // nothing to do
                }
                FileDownload(response.data, currentFileName);
            })
            .catch((err) => {
                onError?.(err);
            });
    };
