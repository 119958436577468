import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { PopupInfo } from '@components/PopupInfo';

import styles from './styles.module.scss';

const StyledPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

const getFrom = (location, userInfo) => {
    if (userInfo.registrationStatus === 'info_required') {
        return { pathname: '/info' };
    }

    return location?.state?.from || { pathname: '/' };
};

export const Authentication = withRouter(({ location, children, ...props }) => {
    const { info, token } = useSelector((state) => state.user);
    const [popup, setPopup] = useState({ open: false, content: '', title: '' });

    if (token) {
        return <Redirect to={getFrom(location, info)} />;
    }

    return (
        <div className={styles.wrapper}>
            <StyledPopup
                modal
                closeOnDocumentClick
                open={popup.open}
                onClose={() => setPopup({ ...popup, open: false })}
            >
                {(close) => (
                    <PopupInfo title={popup.title} close={close}>
                        {popup.content}
                    </PopupInfo>
                )}
            </StyledPopup>

            <img alt='logo' src='/logo.svg' className={styles.logo} />
            <div className={styles.container}>
                {children({ ...props, location, setPopup, popup })}
            </div>

            <div className={styles.bottom}>
                <img alt='icon' src='/facebook_icon.svg' className={styles.icon} />
                <img alt='icon' src='/linkedin_icon.svg' />
                <div className={styles.copyright}>GetTask © 2022</div>
            </div>
        </div>
    );
});
