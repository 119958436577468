import { fetch } from './fetch';

export const fetchRequests = fetch('FETCH_REQUESTS', 'gt/customer/v2/delivery/request/');
export const fetchRequestsOnMap = fetch(
    'FETCH_REQUESTS_ON_MAP',
    'gt/customer/v2/delivery/map/request/'
);
export const fetchRequestsOnMapAutocomplete = fetch(
    'FETCH_REQUESTS_ON_MAP_AUTOCOMPLETE',
    'gt/customer/v2/delivery/map/autocomplete/request/'
);
export const updateRequest = fetch('UPDATE_REQUEST', 'gt/customer/v2/delivery/request/update/', {
    method: 'POST',
});
export const updateAdressRequest = fetch(
    'UPDATE_REQUEST',
    'gt/customer/v2/delivery/request/item/update/',
    {
        method: 'POST',
    }
);
export const cancelRequest = fetch('CANCEL_REQUEST', 'gt/customer/cancel_request/', {
    method: 'POST',
});
export const removeRequest = fetch('REMOVE_REQUEST', 'gt/customer/remove_request/', {
    method: 'POST',
});
export const createRequest = fetch('CREATE_REQUEST', 'gt/customer/v2/delivery/request/create/', {
    method: 'POST',
});
export const addAddressToRequest = fetch(
    'CREATE_REQUEST',
    'gt/customer/v2/delivery/request/item/create/',
    {
        method: 'POST',
    }
);

export const moveItem = fetch('MOVE_ITEM', 'gt/customer/move_item/', { method: 'POST' });
export const removeItem = fetch('REMOVE_ITEM', 'gt/customer/remove_item/', { method: 'POST' });
export const copyItem = fetch('COPY_ITEM', 'gt/customer/copy_item/', { method: 'POST' });
export const fetchLocation = fetch('FETCH_LOCATIONS', 'gt/customer/location_autocomplete/');

export function setFilter(filterStatus) {
    return {
        type: 'UPDATE_REQUESTS_FILTER',
        payload: { filterStatus },
    };
}

export function setSorting(sorting) {
    return {
        type: 'UPDATE_REQUESTS_SORTING',
        payload: { sorting },
    };
}
