import styles from './styles.module.scss';

export const ButtonsContainer = ({ left, right }) => {
    return (
        <div className={styles.buttonsContainer}>
            <div className={styles.buttonsContainerLeft}>{left}</div>

            <div className={styles.buttonsContainerRight}>{right}</div>
        </div>
    );
};
