import { useState } from 'react';
import { Upload } from 'antd';
import axios from 'axios';

import { Button } from '@components/Button';
import { BACKEND_URL } from '@utils/constants';
import { getToken } from '@utils/token';

import styles from './styles.module.scss';

const importIcon = <img alt='icon' src='/imports_icon_white.svg' className={styles.icon} />;

export const UploadFilePopup = ({ close, name, endpoint, onSuccess, onFailure }) => {
    const [files, setFiles] = useState([]);
    const token = getToken();

    const onFileChange = (file) => {
        setFiles((prev) => [...prev, file]);
        return false;
    };

    const onFileRemove = (currentFile) => {
        setFiles((prev) => {
            return prev.filter((file) => file !== currentFile);
        });
    };

    const onFileUpload = async () => {
        try {
            close();
            if (files.length) {
                const formData = new FormData();

                files.map((file) => formData.append(name, file, file.name));

                const { status, data } = await axios.post(`${BACKEND_URL}${endpoint}`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (status === 200) {
                    onSuccess?.();
                }

                if (status !== 200) {
                    onFailure?.(data.message);
                }
            }
        } catch (err) {
            onFailure(err.response ? err.response.data.message : err.message);
        }
    };

    return (
        <div className={styles.popup}>
            <div onClick={close} className={styles.popupClose}>
                <img alt='icon' src='/close_icon.svg' />
            </div>

            <div className={styles.popupTitle}>Загрузить файл</div>

            <div className={styles.popupContent}>
                <Upload
                    multiple
                    beforeUpload={onFileChange}
                    customRequest={() => {}}
                    onRemove={onFileRemove}
                >
                    <Button icon={importIcon}>Загрузить</Button>
                </Upload>
            </div>

            <Button onClick={onFileUpload} className={styles.button}>
                Отправить
            </Button>
        </div>
    );
};
