import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { Typography } from 'antd';
import styled from 'styled-components';

import { Editable } from '@components/Editable';
import { PopupInfo } from '@components/PopupInfo';
import { addContact, fetchContacts, updateContacts } from '@store/actions';

import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

export const ContactsTable = () => {
    const contactsList = useSelector((state) => state.contacts.list);
    const dispatch = useDispatch();

    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [position, setPosition] = useState('');

    const updateContactsHandler = () => {
        dispatch(fetchContacts());
    };

    useEffect(updateContactsHandler, []);

    const clearInputs = () => {
        setName('');
        setEmail('');
        setPhone('');
        setPosition('');
    };

    const submitForm = async (onClose) => {
        try {
            await dispatch(
                addContact({
                    full_name: name,
                    email,
                    phone,
                    position,
                })
            );
            setInfoPopup({
                open: true,
                content: 'Контакт успешно создан',
                title: 'Результат',
                onClose,
            });

            dispatch(fetchContacts());
        } catch (err) {
            setInfoPopup({
                open: true,
                content:
                    err.error.response.data.message ||
                    `${err.error.response.status} ${err.error.response.statusText}`,
                title: 'Ошибка',
                onClose: null,
            });
        }
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const onNameChange = (e) => {
        setName(e.target.value);
    };
    const onPhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const onPositionChange = (e) => {
        setPhone(e.target.value);
    };

    return (
        <div className={styles.contacts}>
            <div className={styles.line4}>
                <Typography.Title className={styles.smallTitle} level={4}>
                    Контактные лица
                </Typography.Title>

                <ShortPopup
                    trigger={
                        <div className={styles.buttonsContainerItem}>
                            <img
                                alt='icon'
                                src='/add_icon.svg'
                                className={styles.buttonsContainerIcon1}
                            />
                            <div>Добавить контакт</div>
                        </div>
                    }
                    onClose={clearInputs}
                    modal
                    closeOnDocumentClick
                >
                    {(close) => (
                        <div className={styles.viewPopup}>
                            <div onClick={close} className={styles.viewPopupClose}>
                                <img alt='icon' src='/close_icon.svg' />
                            </div>
                            <div className={styles.viewPopupTitle}>Новый контакт</div>
                            <div className={styles.container1}>
                                <div className={styles.inputWrapper}>
                                    <div className={styles.label}>ФИО</div>
                                    <input value={name} onChange={onNameChange} />
                                </div>
                                <div className={styles.inputWrapper}>
                                    <div className={styles.label}>Почта</div>
                                    <input value={email} onChange={onEmailChange} />
                                </div>
                                <div className={styles.inputWrapper}>
                                    <div className={styles.label}>Телефон</div>
                                    <input value={phone} onChange={onPhoneChange} />
                                </div>
                                <div className={styles.inputWrapper}>
                                    <div className={styles.label}>Должность</div>
                                    <input value={position} onChange={onPositionChange} />
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    submitForm(close);
                                }}
                                className={styles.popupButton}
                            >
                                Создать новый контакт
                            </div>
                            <ShortPopup
                                modal
                                closeOnDocumentClick
                                open={infoPopup.open}
                                onClose={() => {
                                    setInfoPopup({ ...infoPopup, open: false });
                                    if (infoPopup.onClose) {
                                        infoPopup.onClose();
                                    }
                                }}
                            >
                                {(close) => (
                                    <PopupInfo title={infoPopup.title} close={close}>
                                        {infoPopup.content}
                                    </PopupInfo>
                                )}
                            </ShortPopup>
                        </div>
                    )}
                </ShortPopup>
            </div>
            <table className={styles.table}>
                <tbody>
                    <tr>
                        <td>
                            <div>ФИО</div>
                        </td>
                        <td>
                            <div>Почта</div>
                        </td>
                        <td>
                            <div>Телефон</div>
                        </td>
                        <td>
                            <div>Должность</div>
                        </td>
                    </tr>
                    <tr>
                        <td />
                    </tr>
                    {contactsList.map((c, i) => {
                        const onContactsUpdate = (field) => (value) => {
                            dispatch(updateContacts({ field, value, pk: c.pk }));
                        };

                        return (
                            <tr key={i}>
                                <td>
                                    <div>
                                        <Editable
                                            text={c.full_name}
                                            onSuccess={updateContactsHandler}
                                            submitFunction={onContactsUpdate('full_name')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Editable
                                            text={c.email}
                                            onSuccess={updateContactsHandler}
                                            submitFunction={onContactsUpdate('email')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Editable
                                            text={c.phone}
                                            onSuccess={updateContactsHandler}
                                            submitFunction={onContactsUpdate('phone')}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <Editable
                                            text={c.position}
                                            onSuccess={updateContactsHandler}
                                            submitFunction='position'
                                        />
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
