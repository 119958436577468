import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { accountInfo } from '@store/actions';
import { getToken } from '@utils';

export const withUserInfo = (Component) => () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const reload = async () => {
            if (getToken()) {
                try {
                    await dispatch(accountInfo());

                    setTimeout(() => reload(), 50000);
                } catch {
                    setTimeout(() => reload(), 10000);
                }
            }
        };

        reload();
    }, [getToken()]);

    return <Component />;
};
