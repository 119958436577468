import { fetch } from './fetch';

export const obtainToken = fetch('OBTAIN_TOKEN', 'gt/customer/obtain_token/', { method: 'POST' });
export const signup = fetch('SIGNUP', 'gt/customer/signup/', { method: 'POST' });
export const resetPassword = fetch('RESET_PASSWORD', 'gt/customer/reset_password/', {
    method: 'POST',
});
export const updatePassword = fetch('UPDATE_PASSWORD', 'gt/customer/update_password/', {
    method: 'POST',
});
export const finishRegistration = fetch('FINISH_REGISTRATION', 'gt/customer/finish_registration/', {
    method: 'POST',
});

export const logout = () => {
    return {
        type: 'LOG_OUT',
    };
};
