import { addAddressToRequest, createRequest } from '@store/actions';

const { useDispatch } = require('react-redux');

export const useCreateAddresOrRequest = () => {
    const dispatch = useDispatch();

    const onCreateAddresOrRequest = async ({ routeId, value, date, time }) => {
        const [intervalBegin, intervalEnd] = time;
        const formattedVolume = Number(value.volume.replace(',', '.'));
        const isExistRoute = routeId !== -1;

        const {
            index,
            mass,
            places,
            shipment_type,
            address,
            has_elevator,
            driver,
            phones,
            workers_required,
            max_size = null,
            floor = null,
            carrying_distance = null,
        } = value;

        if (isExistRoute) {
            await dispatch(
                addAddressToRequest(
                    {},
                    {
                        request: routeId,
                        interval_begin: intervalBegin,
                        interval_end: intervalEnd,
                        code: index,
                        mass,
                        volume: formattedVolume,
                        place_count: places,
                        shipment_type,
                        address: address.value,
                        has_elevator,
                        max_size,
                        floor,
                        carrying_distance,
                    }
                )
            );
        } else {
            await dispatch(
                createRequest(
                    {},
                    {
                        location: null,
                        driver_name: driver,
                        driver_phones: phones,
                        date,
                        items: [
                            {
                                mass,
                                shipment_type,
                                has_elevator,
                                workers_required,
                                code: index,
                                place_count: places,
                                interval_begin: intervalBegin,
                                interval_end: intervalEnd,
                                address: address.value,
                                volume: formattedVolume,
                                carrying_distance,
                                floor,
                                max_size,
                            },
                        ],
                    }
                )
            );
        }
    };

    return onCreateAddresOrRequest;
};
