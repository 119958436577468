import Cookies from 'js-cookie';

export const getToken = () => Cookies.get('token') || localStorage.getItem('token');
export const setToken = (token, rememberMe = false) => {
    if (rememberMe) {
        localStorage.setItem('token', token);
    }
    Cookies.set('token', token, { expires: 1 / 24, path: '/' });
};
export const removeToken = () => {
    localStorage.removeItem('token');
    Cookies.remove('token', { path: '/' });
};
