import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

export const Warning = () => {
    const registrationStatus = useSelector((state) => state.user.info.registrationStatus);

    switch (registrationStatus) {
        case 'info_required':
            return (
                <div className={styles.warning}>
                    <svg style={{ fill: '#FD1B1B' }}>
                        <use xlinkHref='/warning_icon.svg#root' />
                    </svg>
                    Прежде чем начать пользоваться системой заполните данные о вашей организации и
                    добавьте контактное лицо.
                </div>
            );
        case 'scan_required':
            return (
                <div className={styles.warning}>
                    <svg style={{ fill: '#FD1B1B' }}>
                        <use xlinkHref='/warning_icon.svg#root' />
                    </svg>
                    Скачайте договор и приложите скан.
                </div>
            );
        case 'confirmation_required':
            return (
                <div className={styles.warning} style={{ color: '#00e640' }}>
                    <svg style={{ fill: '#00e640' }}>
                        <use xlinkHref='/warning_icon.svg#root' />
                    </svg>
                    Скан успешно прикреплен и ожидает проверки.
                </div>
            );
        default:
            return null;
    }
};
