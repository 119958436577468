import { Form } from 'antd';
import clsx from 'clsx';

import { Button } from '@components/Button';

import styles from './styles.module.scss';

const requiredTouchedList = [
    'index',
    'volume',
    'mass',
    'places',
    'workers_required',
    'shipment_type',
    'address',
];

const requiredList = [...requiredTouchedList, 'date', 'time'];

export const FormControls = () => {
    return (
        <Form.Item shouldUpdate>
            {({ getFieldsError, getFieldsValue }) => {
                const isErrors = getFieldsError(requiredList).some(({ errors }) => errors.length);
                const isNotValues = !Object.values(getFieldsValue(requiredList)).every((value) => {
                    if (Array.isArray(value)) {
                        return value.every((valueItem) => valueItem);
                    }

                    return value;
                });

                const submitStyle = clsx(styles.button, {
                    [styles.disabled]: isErrors || isNotValues,
                });

                return (
                    <Button htmlType='submit' className={submitStyle}>
                        Создать новую заявку
                    </Button>
                );
            }}
        </Form.Item>
    );
};
