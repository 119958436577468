import { forwardRef, useEffect, useState } from 'react';
import { AddressSuggestions } from 'react-dadata';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import Popup from 'reactjs-popup';
import { Checkbox, Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';

import { CustomAutocomplete } from '@components/CustomAutocomplete';
import { FormItem as UIFormItem } from '@components/FormItem';
import { Input } from '@components/Input';
import { NumberInput } from '@components/NumberInput';
import { PopupInfo } from '@components/PopupInfo';
import { TimePicker } from '@components/TimePicker';
import { BACKEND_URL, DADATA_API_KEY, getToken } from '@utils';

import { AutoFillItem } from './AutoFillItem';
import { DateViewer } from './DateViewer';
import { FormControls } from './FormControls';
import { FormItemDependentOnRoute } from './FormItemDependentOnRoute';
import { useCreateAddresOrRequest } from './hook';
import { Label } from './Label';
import { TimePopup } from './TimePopup';
import { formatError, nameItemsList } from './utils';

import 'react-dadata/dist/react-dadata.css';
import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        flex: 0 0 0 !important;
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

const FormItem = forwardRef(({ children, label, ...props }, ref) => {
    return (
        <UIFormItem
            {...props}
            ref={ref}
            label={<Label>{label}</Label>}
            marginBottom={0}
            notInfoMessage
            colon={false}
            isLabelVertical
        >
            {children}
        </UIFormItem>
    );
});

const formatDate = (date) => (date ? moment(date).format('DD.MM.YYYY') : '');

const transformToPossibleDisabledItem =
    (WrappedComponent, name, disableList, disablePropName = 'disable') =>
    (props) => {
        const isDisabled = disableList ? disableList.includes(name) : false;

        return <WrappedComponent {...props} {...{ [disablePropName]: isDisabled }} />;
    };

const getInitialState = (route) => ({
    time: [null, null],
    has_elevator: true,
    date: route?.date && moment(route.date, 'YYYY.MM.DD').toDate(),
});

const requiredRule = [{ required: true, message: '' }];

const timeRequiredRule = [
    {
        message: '',
        type: 'boolean',
        validator(_, value) {
            if (value.every((time) => !!time)) {
                return Promise.resolve();
            }
            return Promise.reject();
        },
    },
];
export const NewRequestPopup = ({ close, route, onSuccess, disableList }) => {
    const onCreateAddresOrRequest = useCreateAddresOrRequest();
    const [form] = useForm();

    const [routeOptions, setRouteOptions] = useState([]);
    const [routeId, setRouteId] = useState(route ? route.routeId : -1);
    const [datePopupOpen, setDatePopupOpen] = useState(false);
    const [timePopupOpen, setTimePopupOpen] = useState(false);
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });

    const date = form.getFieldValue('date');

    const routeSearch = form.getFieldValue('route');

    useEffect(() => {
        updateRouteOptions();
    }, [routeSearch, date]);

    // TODO: переделать на общий запрос из экшиона
    const updateRouteOptions = async () => {
        if (!date) return;

        const { data } = await axios({
            method: 'get',
            url: `${BACKEND_URL}gt/customer/request_autocomplete`,
            params: {
                q: routeSearch,
                forward: JSON.stringify({
                    date: formatDate(date),
                }),
            },
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        });

        setRouteOptions(data.results.map((r) => ({ text: r.text, id: +r.id })));
    };

    const submitForm = async ({ date, time, ...value }) => {
        try {
            await onCreateAddresOrRequest({
                date: formatDate(date),
                routeId,
                value,
                time,
            });

            setInfoPopup({
                open: true,
                content: 'Заявка успешно создана',
                title: 'Результат',
            });

            onSuccess();
        } catch (err) {
            const errorTitle = err?.error?.response?.data?.detail || 'Ошибка';

            const defaultMessage = `${err.error.response.status} ${err.error.response.statusText}`;
            const errorMessage = formatError(err?.error?.response?.data?.messages, defaultMessage);

            setInfoPopup({
                open: true,
                content: errorMessage,
                title: errorTitle,
            });
        }
    };

    const PossibleDisableCustomAutocomplete = transformToPossibleDisabledItem(
        CustomAutocomplete,
        'route',
        disableList,
        'disabled'
    );

    const initialState = getInitialState(route);

    return (
        <>
            <AutoFillItem form={form} routeId={routeId} />
            <Form
                labelWrap
                layout='horizontal'
                form={form}
                onFinish={submitForm}
                initialValues={initialState}
            >
                <ShortPopup
                    open={datePopupOpen}
                    onClose={() => setDatePopupOpen(false)}
                    modal
                    closeOnDocumentClick
                >
                    {(close) => (
                        <FormItem noStyle name='date' valuePropName='date' rules={requiredRule}>
                            <Calendar
                                onChange={() => {
                                    close();
                                }}
                                locale={locales.ru}
                                color='#000000'
                                rangeColors={['#000000']}
                            />
                        </FormItem>
                    )}
                </ShortPopup>

                <ShortPopup
                    open={timePopupOpen}
                    onClose={() => setTimePopupOpen(false)}
                    modal
                    closeOnDocumentClick
                >
                    {(close) => (
                        <Form.Item noStyle name='time' rules={timeRequiredRule}>
                            <TimePicker close={close} />
                        </Form.Item>
                    )}
                </ShortPopup>

                <Form.Item noStyle>
                    <Row gutter={16}>
                        <Col span={8}>
                            <FormItem
                                rules={requiredRule}
                                label={nameItemsList.date}
                                name='date'
                                valuePropName='date'
                            >
                                <DateViewer setPopupOpen={setDatePopupOpen} routeId={routeId} />
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem label='Время' name='time' required rules={timeRequiredRule}>
                                <TimePopup setPopupOpen={setTimePopupOpen} />
                            </FormItem>
                        </Col>

                        <Col span={8}>
                            <FormItem
                                label={nameItemsList.index}
                                rules={requiredRule}
                                name='index'
                                notInfoMessage
                            >
                                <Input className={styles.form_input} />
                            </FormItem>
                        </Col>
                    </Row>
                </Form.Item>

                <FormItem label={nameItemsList.route} name='route'>
                    <PossibleDisableCustomAutocomplete
                        options={routeOptions}
                        onIdChange={setRouteId}
                        defaultId={route?.routeId || -1}
                    />
                </FormItem>

                <Form.Item noStyle>
                    <Row gutter={16}>
                        <Col span={6}>
                            <FormItem
                                label={nameItemsList.volume}
                                name='volume'
                                rules={requiredRule}
                            >
                                <NumberInput className={styles.form_input} />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem label={nameItemsList.mass} name='mass' rules={requiredRule}>
                                <NumberInput className={styles.form_input} />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem
                                label={nameItemsList.places}
                                name='places'
                                rules={requiredRule}
                            >
                                <NumberInput className={styles.form_input} />
                            </FormItem>
                        </Col>

                        <Col span={6}>
                            <FormItem
                                label={nameItemsList.workers_required}
                                name='workers_required'
                                rules={requiredRule}
                            >
                                <NumberInput className={styles.form_input} />
                            </FormItem>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item noStyle>
                    <Row gutter={16}>
                        <Col span={7}>
                            <FormItem label={nameItemsList.max_size} name='max_size'>
                                <Input className={styles.form_input} />
                            </FormItem>
                        </Col>

                        <Col span={7}>
                            <FormItem label={nameItemsList.floor} name='floor'>
                                <Input className={styles.form_input} />
                            </FormItem>
                        </Col>

                        <Col span={7}>
                            <FormItem
                                label={nameItemsList.carrying_distance}
                                name='carrying_distance'
                            >
                                <Input className={styles.form_input} />
                            </FormItem>
                        </Col>

                        <Col span={3}>
                            <FormItem
                                label={nameItemsList.has_elevator}
                                name='has_elevator'
                                valuePropName='checked'
                            >
                                <Checkbox className={styles.form_checkbox} type='checkbox' />
                            </FormItem>
                        </Col>
                    </Row>
                </Form.Item>

                <FormItem
                    label={nameItemsList.shipment_type}
                    name='shipment_type'
                    rules={requiredRule}
                >
                    <Input className={styles.form_input} />
                </FormItem>

                <FormItem label={nameItemsList.address} name='address' rules={requiredRule}>
                    <AddressSuggestions
                        defaultQuery={route?.address}
                        token={DADATA_API_KEY}
                        containerClassName={styles.addressSuggestions}
                        customInput={Input}
                        inputProps={{
                            className: styles.form_input,
                        }}
                    />
                </FormItem>

                <FormItemDependentOnRoute
                    label={nameItemsList.driver}
                    name='driver'
                    routeId={routeId}
                />

                <FormItemDependentOnRoute
                    label={nameItemsList.phones}
                    name='phones'
                    routeId={routeId}
                />

                <FormControls />
            </Form>

            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => {
                    if (infoPopup.title === 'Результат') {
                        close();
                    } else {
                        setInfoPopup({ ...infoPopup, open: false });
                    }
                }}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        {infoPopup.content}
                    </PopupInfo>
                )}
            </ShortPopup>
        </>
    );
};
