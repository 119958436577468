import { useState } from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { PopupInfo } from '@components/PopupInfo';
import { UploadFilePopup } from '@components/UploadFilePopup';

import styles from './styles.module.scss';

const ShortPopup = styled(Popup)`
    &-content {
        width: inherit !important;
        border-radius: 6px;
        padding: 0 !important;
    }
`;

export const ImportsButton = ({ allow_requests_creation, updateData }) => {
    const [infoPopup, setInfoPopup] = useState({ open: false, content: '' });

    const renderPopup = () => {
        if (allow_requests_creation) {
            return (
                <ShortPopup
                    trigger={
                        <div className={styles.buttonsContainerItem}>
                            <img
                                alt='icon'
                                src='/import_xls_icon.svg'
                                className={styles.buttonsContainerIcon2}
                            />
                            <div />
                        </div>
                    }
                    modal
                    closeOnDocumentClick
                >
                    {(close) => (
                        <UploadFilePopup
                            close={close}
                            name='requests'
                            endpoint='/gt/customer/v2/delivery/request/import/'
                            onSuccess={() => {
                                updateData();
                                setInfoPopup({
                                    open: true,
                                    title: 'Файл импортируется',
                                    content:
                                        'Статус импорта и отчет доступны в разделе "История импорта"',
                                });
                            }}
                            onFailure={(err) => {
                                setInfoPopup({
                                    open: true,
                                    title: 'Ошибка',
                                    content: err,
                                });
                            }}
                        />
                    )}
                </ShortPopup>
            );
        }

        return (
            <div
                onClick={() =>
                    setInfoPopup({
                        open: true,
                        content: 'Пополните баланс для подачи заявок',
                        title: 'Недостаточно средств',
                    })
                }
                className={styles.buttonsContainerItem}
            >
                <img
                    alt='icon'
                    src='/import_xls_icon.svg'
                    className={styles.buttonsContainerIcon2}
                />
                <div />
            </div>
        );
    };
    return (
        <>
            {renderPopup()}
            <ShortPopup
                modal
                closeOnDocumentClick
                open={infoPopup.open}
                onClose={() => setInfoPopup({ ...infoPopup, open: false })}
            >
                {(close) => (
                    <PopupInfo title={infoPopup.title} close={close}>
                        <div dangerouslySetInnerHTML={{ __html: infoPopup.content }} />
                    </PopupInfo>
                )}
            </ShortPopup>
        </>
    );
};
