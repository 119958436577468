import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { Button, Checkbox, FormItem, Input, Label } from '@components';
import { withAuthentication } from '@hocs';
import { signup } from '@store/actions';

import styles from './styles.module.scss';

const defaultRule = [{ required: true, message: 'Это поле обязательно' }];

const checkBoxRule = [
    {
        message: '',
        type: 'boolean',
        transform: (value) => {
            if (!value) {
                return undefined;
            }
            return value;
        },
        required: true,
    },
];

export const SignupPage = withAuthentication(({ history, setPopup }) => {
    const dispatch = useDispatch();
    const [form] = useForm();
    const [isError, setIsError] = useState(true);

    const onSignup = async ({ name, email, password, phone, organizationName }) => {
        try {
            await dispatch(
                signup(undefined, {
                    organization_name: organizationName,
                    full_name: name,
                    email,
                    password,
                    phone,
                })
            );

            setPopup({
                open: true,
                title: 'Информация',
                content:
                    'Проверьте свою почту и перейдите по ссылке в письме для завершения регистрации.',
                onClose: () => history.push('/login'),
            });
        } catch (err) {
            setPopup({
                open: true,
                title: 'Ошибка',
                content:
                    err?.error?.response?.data?.message ||
                    `${err?.error?.response?.status} ${err?.error?.response?.statusText}`,
            });
        }
    };

    const onFieldsChange = (_, fields) => {
        const isError = fields.some(({ errors, value }) => value === undefined || errors.length);

        setIsError(isError);
    };

    return (
        <Form
            style={{ width: 320 }}
            form={form}
            onFinish={onSignup}
            onFieldsChange={onFieldsChange}
        >
            <Typography.Title level={5} ellipsis style={{ fontSize: 24, marginBottom: 0 }}>
                Регистрация
            </Typography.Title>

            <FormItem
                marginBottom={0}
                rules={defaultRule}
                isLabelVertical
                required={false}
                label={<Label>Ваше имя</Label>}
                name='name'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                marginBottom={0}
                rules={defaultRule}
                required={false}
                isLabelVertical
                label={<Label>Название организации</Label>}
                name='organizationName'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                marginBottom={0}
                rules={defaultRule}
                isLabelVertical
                required={false}
                label={<Label>Телефон</Label>}
                name='phone'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                marginBottom={0}
                required={false}
                rules={defaultRule}
                isLabelVertical
                label={<Label>Email</Label>}
                name='email'
            >
                <Input size='large' />
            </FormItem>

            <FormItem
                rules={defaultRule}
                isLabelVertical
                required={false}
                marginBottom={24}
                label={<Label>Пароль</Label>}
                name='password'
            >
                <Input type='password' size='large' />
            </FormItem>

            <FormItem
                help=''
                rules={checkBoxRule}
                marginBottom={0}
                name='isAcceptServiceRule'
                valuePropName='checked'
            >
                <Checkbox>
                    Я соглашаюсь с{' '}
                    <a href='https://gettask.ru/rules.doc' className={styles.checkbox_link}>
                        Правилами сервиса
                    </a>
                </Checkbox>
            </FormItem>

            <FormItem
                rules={checkBoxRule}
                marginBottom={12}
                name='isAcceptPdp'
                help=''
                valuePropName='checked'
            >
                <Checkbox>
                    Я соглашаюсь с{' '}
                    <a
                        href='https://gettask.ru/personal_data_policy.doc'
                        className={styles.checkbox_link}
                    >
                        Соглашением на обработку персональных данных
                    </a>
                </Checkbox>
            </FormItem>

            <FormItem>
                <Button
                    disabled={isError}
                    className={styles.singup_button}
                    size='large'
                    type='primary'
                    htmlType='submit'
                >
                    Зарегистрироваться
                </Button>
            </FormItem>
        </Form>
    );
});
