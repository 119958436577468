import { Redirect, Route, Switch } from 'react-router-dom';

import { Sidebar } from '@components';
import {
    BalancePage,
    DailyConfirmationPage,
    FinishRegistration,
    ImportHistoryPage,
    InfoPage,
    LoginPage,
    ReportsPage,
    RequestsOnMapPage,
    RequestsPage,
    ResetPasswordPage,
    SignupPage,
    UpdatePasswordPage,
} from '@pages';

import { PrivateRoute } from './PrivateRouter';

export const Routes = () => {
    return (
        <Switch>
            <PrivateRoute exact path='/requests'>
                <Sidebar menu='requests' />
                <RequestsPage />
            </PrivateRoute>

            <PrivateRoute exact path='/requests_on_map'>
                <Sidebar menu='requests_on_map' />
                <RequestsOnMapPage />
            </PrivateRoute>

            <PrivateRoute exact path='/reports'>
                <Sidebar menu='reports' />
                <ReportsPage />
            </PrivateRoute>

            <PrivateRoute exact path='/info'>
                <Sidebar menu='info' />
                <InfoPage />
            </PrivateRoute>

            <PrivateRoute exact path='/imports'>
                <Sidebar menu='imports' />
                <ImportHistoryPage />
            </PrivateRoute>

            <PrivateRoute exact path='/balance'>
                <Sidebar menu='balance' />
                <BalancePage />
            </PrivateRoute>

            <Route exact path='/daily_reconciliation/:uid'>
                <DailyConfirmationPage />
            </Route>

            <Route exact path='/login'>
                <LoginPage />
            </Route>

            <Route exact path='/signup'>
                <SignupPage />
            </Route>

            <Route exact path='/reset_password'>
                <ResetPasswordPage />
            </Route>

            <Route exact path='/update_password/:uid'>
                <UpdatePasswordPage />
            </Route>

            <Route path='/finish_registration/:uid' component={FinishRegistration} />

            <Route path='/'>
                <Redirect to='/requests' />
            </Route>
        </Switch>
    );
};
