import { Input } from '@components/Input';

import styles from './styles.module.scss';

const formatValue = ([intervalBegin, intervalEnd]) => {
    if (intervalBegin && intervalEnd) {
        return `${intervalBegin}-${intervalEnd}`;
    }

    return null;
};

export const TimePopup = ({ setPopupOpen, value }) => {
    const onPopupOpen = () => {
        setPopupOpen(true);
    };

    return <Input onClick={onPopupOpen} value={formatValue(value)} className={styles.datePicker} />;
};
