import { withUserInfo } from '@hocs';
import { Routes } from '@pages/Routes';

import './styles/index.scss';
import 'antd/dist/antd.css';
import styles from './App.module.scss';

export const App = withUserInfo(() => {
    return (
        <div className={styles.app}>
            <Routes />
        </div>
    );
});
