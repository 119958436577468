import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import moment from 'moment';

import { ButtonsContainer, CustomAutocomplete, CustomSelectWithStorage } from '@components';
import {
    fetchLocation,
    fetchRequestsOnMap,
    fetchRequestsOnMapAutocomplete,
    setSelectedMapRequestId,
    setSelectedMapRequestType,
} from '@store/actions';
import { REQUESTS_ON_MAP_PREFIX, storageService } from '@utils';

import styles from './Filters.module.scss';

const localStorageService = storageService(REQUESTS_ON_MAP_PREFIX);

const titlePrefix = (count) => {
    const r100 = count % 100;
    const r10 = count % 10;
    let name = 'заявок';
    if (r100 === 1 || (r100 > 20 && r10 === 1)) {
        name = 'заявка';
    } else if ([2, 3, 4].includes(r100) || (r100 > 20 && [2, 3, 4].includes(r10))) {
        name = 'заявки';
    }

    return `${count} ${name} в`;
};

export const Filters = () => {
    const dispatch = useDispatch();
    const {
        delivery_requests_autocomplete,
        selected_request_id,
        selected_request_type,
        timestamp,
        requestsNum,
    } = useSelector((state) => ({
        requestsNum: state.requests_on_map.mapData.delivery_requests.length,
        timestamp: state.requests_on_map.mapData.timestamp,
        delivery_requests_autocomplete:
            state.requests_on_map?.delivery_requests_autocomplete?.results || [],
        selected_request_id: state.selected_request_on_map.id,
        selected_request_type: state.selected_request_on_map.request_type,
    }));
    const [locations, setLocations] = useState([]);

    const onFetchLocations = async () => {
        const response = await dispatch(fetchLocation());
        const locations = response.payload.data.results.map((item) => ({
            id: +item.id,
            text: item.text,
        }));

        setLocations(locations);
    };

    useEffect(() => {
        onFetchLocations();
    }, []);

    const selectedRequestText = () => {
        const request = delivery_requests_autocomplete.find(
            (item) => item.id === selected_request_id
        );
        if (request) {
            return request.text;
        }
        if (selected_request_id !== -1) {
            return selected_request_id;
        }
        return '';
    };

    const onFetchRequestsOnMapAutocomplete = (text) => {
        const params = { q: text };
        const map_location = localStorageService.get('map_location');
        if (map_location !== -1) {
            params.forward = JSON.stringify({
                location: map_location,
                request_type: selected_request_type,
            });
        }

        dispatch(fetchRequestsOnMapAutocomplete(params));
    };

    const [requestText, setRequestText] = useState(selectedRequestText());
    useEffect(() => {
        const text = selected_request_id === -1 ? '' : selected_request_id;
        onFetchRequestsOnMapAutocomplete(text);
    }, []);
    useEffect(() => {
        setRequestText(selectedRequestText());
    }, [selected_request_id]);

    let timestampText = '';
    if (timestamp) {
        timestampText = `на ${moment(timestamp).format('HH:mm')}`;
    }

    const onUpdateClick = () => {
        const map_location = localStorageService.get('map_location');
        dispatch(fetchRequestsOnMap({ location: map_location }));

        dispatch(setSelectedMapRequestId(-1));
        onFetchRequestsOnMapAutocomplete();
    };

    const onRequestTextChanged = (text) => {
        setRequestText(text);
        onFetchRequestsOnMapAutocomplete(text);
    };

    const requestTypes = [
        { text: 'Опоздания', id: 'expiring_only' },
        { text: 'Задержка назначения', id: 'assignment_delay_only' },
    ];

    const onRequestTypeChanged = () => {
        const selectedRequestType = localStorageService.get('selected_request_on_map_type');
        dispatch(setSelectedMapRequestType(selectedRequestType || 'all'));
    };
    useEffect(() => {
        onRequestTypeChanged();
    }, []);

    return (
        <ButtonsContainer
            left={
                <Space size='small'>
                    <span style={{ alignItems: 'center', display: 'flex' }}>
                        {titlePrefix(requestsNum)}
                    </span>
                    <CustomSelectWithStorage
                        options={locations.map(({ text, id }) => ({ label: text, value: id }))}
                        localStorageService={localStorageService}
                        placeholder='Филиал'
                        optionName='map_location'
                        size='large'
                        width={200}
                        allowClear
                    />
                    <span style={{ alignItems: 'center', display: 'flex' }}>{timestampText}</span>
                    <span onClick={onUpdateClick} className={styles.button}>
                        Обновить
                    </span>
                </Space>
            }
            right={
                <>
                    <span style={{ width: '200px', marginRight: '16px' }}>
                        <CustomSelectWithStorage
                            options={requestTypes.map(({ text, id }) => ({
                                label: text,
                                value: id,
                            }))}
                            placeholder='Все'
                            localStorageService={localStorageService}
                            updateData={onRequestTypeChanged}
                            optionName='selected_request_on_map_type'
                            size='large'
                            width={200}
                            allowClear
                        />
                    </span>
                    <span style={{ width: '240px' }}>
                        <CustomAutocomplete
                            options={delivery_requests_autocomplete}
                            value={requestText}
                            placeholder='Заявка'
                            onChange={onRequestTextChanged}
                            onIdChange={(id) => {
                                dispatch(setSelectedMapRequestId(id));
                            }}
                            defaultId={selected_request_id}
                            disabled={false}
                            allowClear
                        />
                    </span>
                </>
            }
        />
    );
};
